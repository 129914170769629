@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.layout-teasing {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  .layout-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    border-radius: 20px;
    width: 584px;
    padding: 40px 20px;
    margin-top: -80px;

    .container-rule-button {
      width: 430px;
      background: url('../../../resources/img/deco.png') no-repeat;
      background-position: center right;
      background-size: 270px 160px;
      margin-inline: calc(50% - 170px);
    }
    .layout-video {
      background: url('../../../resources/img/ellipse.png') no-repeat;
      background-position: bottom 0px right 90px;
      background-size: 200px;
      display: flex;
      flex-direction: column;
      justify-content: top;
      align-items: center;
      align-self: center;
      height: 630px;
      width: 700px;
      .video {
        border-radius: 20px;
        border: none;
      }

      .title-video {
        font-family: $default-font, sans-serif;
        font-size: 35px;
        font-weight: 100;
        margin-inline: auto;
      }
    }
  }
  .share-layout {
    width: 50%;
    align-self: center;
    margin-block: 80px;
  }

  @include mq($until: mobile) {
    .layout-content {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      border-radius: 20px;
      width: 80%;
      .container-rule-button {
        width: 100%;
        margin: 0 auto;
        background: none;
        background-position: none;
        background-size: inherit;
        margin-inline: 0;
        display: flex;
        justify-content: center;

        button {
          width: 90%;
        }
      }
      .layout-video {
        background: url('../../../resources/img/ellipse.png') no-repeat;
        background-position: bottom 0px right 90px;
        background-size: 200px;
        display: flex;
        flex-direction: column;
        justify-content: top;
        align-items: center;
        align-self: center;
        height: 630px;
        width: 700px;
        .video {
          border-radius: 50px;
        }

        .title-video {
          font-family: $default-font, sans-serif;
          font-size: 35px;
          font-weight: 100;
          margin-inline: auto;
        }
      }
    }
    .share-layout {
      width: 100%;
    }
  }
}

.layout-faq {
  display: flex;
  flex-direction: column;

  .deco2 {
    margin-top: 30px;
    position: relative;
    left: calc(50% - 160px);
  }
  .title-faq {
    margin-inline: auto;
    margin-top: -20px;
    font-size: 35px;
    font-family: $default-font, sans-serif;
    font-weight: 100;
    color: white;
  }
  .faq-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex: 0 0 auto;
    .left-column,
    .right-column {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
    .card-faq {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 8px;
      border: none;
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);

      .quest-faq {
        font-family: $default-font, sans-serif;
        font-weight: 100;
        border-radius: 8px 8px 0 0;
        background-color: #2e70de;
        color: white;
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
      }
      .answer-faq {
        font-family: Arial;
        color: black;
        padding: 10px;
        overflow: hidden;
      }
    }
  }
}

@include mq($until: mobile) {
  .layout-faq {
    display: flex;
    flex-direction: column;

    .deco2 {
      margin-top: 30px;
      position: relative;
      width: 100%;
    }
    .title-faq {
      align-self: center;
      margin-top: -20px;
      font-size: 35px;
      font-family: $default-font, sans-serif;
      font-weight: 100;
    }
    // display: flex;
    // flex-direction: column;
    flex: 1;
    .faq-column {
      display: flex;
      flex-direction: column;
      .left-column,
      .right-column {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .card-faq {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 8px;
        width: 100%;
        border: none;
        box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);

        .quest-faq {
          font-family: $default-font, sans-serif;
          font-weight: 100;
          border-radius: 8px 8px 0 0;
          background-color: #2e70de;
          color: white;
          display: flex;
          padding: 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }
        .answer-faq {
          font-family: Arial;
          color: black;
          padding: 10px;
          height: 100%;
          overflow: hidden;
        }
      }
    }
  }
}
