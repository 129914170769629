@import '../../../../../shop//assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.progress-bar {
  // width: 100%;
  width: 150px;
  margin-top: 30px;
  & > div {
    margin: 0 auto;
  }
}

.inputFile {
  margin-bottom: 10px;
  .uploadFileReceipt {
    cursor: pointer;
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin-top: 16px;
    }
    display: flex;
    flex-direction: row;
    gap: 8px;
    background-color: $color-primary;
    padding-inline: 20px;
    border-radius: 30px;

    .uploadImgReceipt {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $color-secondary;
      }
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        margin-right: 5px;
      }
    }

    .uploadLabel {
      color: $color-secondary;
      left: 25.55%;
      right: 0%;
      top: 24.07%;
      bottom: 25.93%;
      font-family: $default-font, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
  }

  .uploadedFileText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 20px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: $color-secondary;
    .deleteImg {
      cursor: pointer;
    }
  }
}

.form-receipt {
  .subtitle {
    font-size: 23px;
  }
  .generic-error-message {
    color: white;
    text-shadow: inherit;
    width: 100%;
  }
}

.layout-receipt-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  .container-receipt-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    line-height: 20px;
    .test-info-receipt {
      text-align: left;
      font-style: normal;
      font-size: 18px;
      color: $color-secondary;
      font-family: Arial;
    }
  }
}

.layout-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@include mq($until: mobile) {
  .inputFile {
    gap: 10px;
    width: 100%;
    .uploadFileReceipt {
      gap: 0;
      height: 60px;

      .uploadImgReceipt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 100%;
        margin-right: 20px;
      }

      .uploadLabel {
        font-size: 18px;
      }
    }
  }
  .uploadedFileText {
    font-size: 13px;
  }
}

.button {
  margin-top: 29px;
}
