@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.footer {
  width: 100.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 10px;
  .sponsors {
    display: none;
  }
  .version-footer {
    font-size: 12px;
    color: white;
    padding-inline: 20px;
    position: absolute;
    right: 10px;
  }
  .options {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    a {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.02em;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      opacity: 0.8;
      text-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      padding-bottom: 6px;
      padding-right: 20px;
      cursor: pointer;
    }
  }
}

@include mq($until: tablet-portrait) {
  .footer {
    background-color: transparent;
    flex-direction: column;
    .version-footer {
      bottom: 3px;
      right: 3px;
    }
    .sponsors {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: transparent img {
        max-width: 100%;
      }
    }
    position: absolute;
    bottom: inherit;
    .options {
      flex-direction: column;
      margin-block: 20px;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.02em;
        padding-bottom: 18px;
      }
    }
  }
}
