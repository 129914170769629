@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.button-area button,
.play-button {
  font-family: $default-font;
  text-align: center;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background-color: $cta-background-color;
  padding: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: $cta-text-color;
  text-decoration: none;
  min-width: 170.67px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  &.disabled {
    color: $color-grey !important;
    pointer-events: none;
    cursor: not-allowed !important;
  }
  &.black-button {
    background-color: $color-secondary;
    color: $cta-text-color;
  }
  &.button-teasing {
    font-size: 18px;
    padding-inline: 40px;
  }
  &.rule-button {
    align-self: center;
    z-index: 50;
  }
}

@include mq($from: mobile, $and: mq($until: m-height, $media-feature: height)) {
  .button-area,
  .play-button {
    font-size: 17px;
  }
}
@include mq($from: mobile, $and: mq($until: s-height, $media-feature: height)) {
  .button-area,
  .play-button {
    font-size: 10px;
    padding: 0 15px;
    min-width: 80px;
  }
}

@include mq(
  $from: mobile,
  $and: mq($until: xs-height, $media-feature: height)
) {
  .button-area,
  .play-button {
    font-size: 8px;
  }
}
